import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import * as JsSIP from 'jssip';
import './phone.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import {
  Alert,
  Button,
  ButtonGroup,
  Container,
  Row,
  Col,
  InputGroup,
  Collapse,
  FormControl,
  Spinner
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faExpand, faWindowMinimize, faExternalLinkAlt, faRandom } from '@fortawesome/free-solid-svg-icons';
import '../font-awesome-animation.css';
import PhoneAudio from '../components/phoneAudio';
import AudioSelector from '../components/audio/audioSelector';
import Dialpad from '../components/audio/dialpad';
import VolumeSlider from '../components/audio/volumeSlider';
import { SwitchFramePosition } from '../components/SwitchFramePosition'
import { CallOrAnswerBtn, DialpadBtn, MuteCallBtn, EndOrRejectBtn, HoldCallBtn, OpenTransferOptionsBtn, SwapBtn } from '../components/callButtons';
import TransferOptions from '../components/transferOptions';
import { createNotification, createSipUaConfig, getFormattedTimeSince, setFrameSize, sendMessageToParentFrame } from '../utils';
import { } from '@testing-library/react';
import { } from '@reduxjs/toolkit';

function Phone({ onPopOut }) {
  JsSIP.debug.disable('JsSIP:*');
  //JsSIP.debug.enable('JsSIP:*');

  const [remoteAudioRtcPeerConnection, setRemoteAudioRtcPeerConnection] = useState(null);

  const params = new URLSearchParams(useLocation().search);

  const [phoneNumberToDial, setPhoneNumberToDial] = useState('');
  const [destinationIsValid, setDestinationIsValid] = useState(false);
  const [registrationStatus, setRegistrationStatus] = useState();
  const [remoteConnectionState, setRemoteConnectionState] = useState('');

  const rtcSession = useRef(null);
  const transferStarted = useRef(false);
  const consultSession = useRef(null);
  const [consultSessionState, setConsultSessionState] = useState('');
  const transferDestination = useRef('');
  const [transferFromNumber, setTransferFromNumber] = useState('');

  const activeRTCSessions = useRef({});
  const previousRTCSessions = useRef([]);

  const secondCallSession = useRef(null);
  const [secondCallState, setSecondCallState] = useState('');

  const [alertShow, setAlertShow] = useState(false);
  const [alertType, setAlertType] = useState('danger');
  const [alertMessage, setAlertMessage] = useState('');

  const [ringing, setRinging] = useState(false);

  const [openDialpad, setOpenDialpad] = useState(false);
  const [openAudioSettings, setOpenAudioSettings] = useState(false);
  const [openTransferSettings, setOpenTransferSettings] = useState(false);
  const [openConsultTransferSettings, setOpenConsultTransferSettings] = useState(false);
  const [activeCallBtn, setActiveCallBtn] = useState(true);
  const [activeMuteBtn, setActiveMuteBtn] = useState(true);
  const [activeHoldBtn, setActiveHoldBtn] = useState(false);
  const [disconnectBtnDisabled, setDisconnectBtnDisabled] = useState(true);
  const [newRtcPeer, setNewRtcPeer] = useState('');

  const [showCallManipulationBtns, setShowCallManipulationBtns] = useState(true);
  const [activeCaller, setActiveCaller] = useState(['to', 0]);

  const userAgent = useRef(null);

  const mediaOptions = useRef('');
  const [audioOutputDeviceId, setAudioOutputDeviceId] = useState('default');
  const [audioOutputVolume, setAudioOutputVolume] = useState(50);

  const ringtone = params.get('ringtone');

  const [containerHeight, setContainerHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(350);
  const containerRef = useRef();

  const [isMinified, setIsMinified] = useState(false);
  const [isExternal, setIsExternal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [time, setTime] = useState(Date.now());

  const [refreshAlert, setRefreshAlert] = useState(false);

  useEffect(() => {
    if (containerRef.current) {
      setTimeout(
        () => {
          if (containerRef.current) {
            setContainerHeight(containerRef.current.getBoundingClientRect().height);
          }
        }, 100
      );
    }
  });

  useEffect(() => {
    if (containerHeight !== 0) {
      setFrameSize(containerHeight, null, containerRef.current);
    }
  }, [containerHeight])

  useEffect(() => {
    if (containerWidth) {
      setFrameSize(null, containerWidth, containerRef.current);
    }
  }, [containerWidth])

  useEffect(() => {
    const userAgentConfig = createSipUaConfig(
      params.get('user'),
      params.get('secret'),
      params.get('server')
    );
    userAgent.current = new JsSIP.UA(userAgentConfig);
    userAgent.current.start();

    mediaOptions.current = {
      mediaConstraints: {
        audio: true,
        video: false,
      },
      pcConfig: {
        rtcpMuxPolicy: 'negotiate', //Workaround for https://blogs.asterisk.org/2017/04/26/rtcp-mux-webrtc/
      },
    };

    if (params.get('minify') === 'false') {
      setIsMinified(false);
    } else {
      setIsMinified(true);
    }

    if (params.get('external') === 'true') {
      setIsExternal(true);
    }

    return () => {
      userAgent.current.stop();
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000); //re-render ever 1 second for timer
    const cleanup = (e) => {
      try {
        sendMessageToParentFrame('return-to-frame');
        userAgent.current.stop();
      }
      catch (ex) {
        //Most likely hit a hyperlink to download something that trigger cross-origin err
        //KER-3821
      }
    }
    window.addEventListener('beforeunload', cleanup);
    return () => {
      clearInterval(interval);
      window.removeEventListener('beforeunload', cleanup);
    }
  }, []);

  useEffect(() => {
    if (!userAgent.current) {
      return;
    }

    let version = 0;
    if (params.has('version')) {
      version = parseInt(params.get('version'))
    }

    // WEBSOCKET/REGISTRATION EVENTS
    userAgent.current.on('connected', () => {
      setRegistrationStatus('Connected');
      showAlert(false);
      console.log('UserAgent:Connected');
    });

    userAgent.current.on('disconnected', () => {
      setRegistrationStatus('Disconnected');
      if (version >= 2020121) {
        setRefreshAlert(true);
      }
      showAlert(true, 'Softphone Disconnected. Please log out and login to the dashboard to reregister.', 'danger');
      setIsMinified(false);
      setActiveCallBtn(true);
      console.log('UserAgent:Disconnected');
    });

    userAgent.current.on('registered', () => {
      setRegistrationStatus('Registered');
      showAlert(false);
      console.log('UserAgent:Registered');
    });

    userAgent.current.on('unregistered', () => {
      setRegistrationStatus('Unregistered');
      if (version >= 2020121) {
        setRefreshAlert(true);
      }
      showAlert(true, 'Softphone Unregistered. Please log out and login to the dashboard to reregister.', 'danger');
      setIsMinified(false);
      setActiveCallBtn(true);
      console.log('UserAgent:Unregistered');
    });

    userAgent.current.on('registrationFailed', () => {
      setRegistrationStatus('Registration failed');
      showAlert(true, 'Softphone Registration Failed. Please contact your administrator.', 'danger');
      setIsMinified(false);
      setActiveCallBtn(true);
      console.log('UserAgent:Registration failed');
    });
    // END WEBSOCKET/REGISTRATION EVENTS

    userAgent.current.on('newRTCSession', async (newRTCSessionEvent) => {
      console.log('newRTCSession', newRTCSessionEvent);
      const session = newRTCSessionEvent.session;
      let sessionId = 0;
      if (session.id) {
        sessionId = session.id;
      }

      if (rtcSession.current != null && secondCallSession.current != null && session.direction !== 'outgoing') {
        console.warn(`There are currently at least two calls. Sending incoming call from ${session.remote_identity.uri.user} to voicemail.`);
        session.terminate();
        return;
      }
      addRTCSession(session, {});

      let callType = 'primary';

      if (rtcSession.current != null && session.direction === 'incoming') {
        secondCallSession.current = session;
        setSecondCallState('Incoming');
        console.warn('Already on a call and a second call is incoming...');
        callType = 'secondary';
      } else {
        rtcSession.current = session;
      }
      activeRTCSessions.current[session.id].properties.type = callType;

      const unparsedPhoneNum = newRTCSessionEvent.session.remote_identity.uri.user;
      //set caller ID info
      const remotePhoneNumber = parsePhoneNumberFromString(unparsedPhoneNum.startsWith('+') ? unparsedPhoneNum : `+1${unparsedPhoneNum}`);
      const displayName = newRTCSessionEvent.session.remote_identity.display_name && newRTCSessionEvent.session.remote_identity.display_name.includes("CID:") ? '' : newRTCSessionEvent.session.remote_identity.display_name;
      activeRTCSessions.current[session.id].properties.remoteCallerName = displayName;
      if (remotePhoneNumber !== undefined) {
        activeRTCSessions.current[session.id].properties.remoteCallerNumber = remotePhoneNumber.formatNational('National');
      } else {
        activeRTCSessions.current[session.id].properties.remoteCallerNumber = unparsedPhoneNum;
      }

      if (newRTCSessionEvent.session.direction === 'outgoing') {
        setNewRtcPeer(callType);
      }

      newRTCSessionEvent.session.on('accepted', (acceptedEvent) => {
        console.log('accepted event', acceptedEvent);
        const session = activeRTCSessions.current[sessionId]
        if (activeRTCSessions.current[sessionId]) {
          if (session.properties.muted) {
            console.log('accepted event: muting the new session', sessionId);
            session.mute();
          }
        }
      });

      newRTCSessionEvent.session.on('failed', (failedEvent) => {
        console.log('failed event', failedEvent);
        const session = activeRTCSessions.current[sessionId];
        switch (failedEvent.cause) {
          case 'Canceled':
          case 'SIP Failure Code':
          case 'Not Found':
          case 'Rejected':
            if (failedEvent.originator === 'remote') {
              showAlert(true, `${session.properties.remoteCallerName ?? session.properties.remoteCallerNumber} disconnected.`, 'danger');
              console.log('Remote Disconnected');
            }
            if (failedEvent.originator === 'local') {
              console.log('Local Disconnected');
            }
            if (failedEvent.message) {
              if (session.properties.type === 'secondary') {
                setSecondCallState('');
              }
            }
            if (Object.keys(activeRTCSessions.current).length === 0) {
              resetUser();
            }
            if (failedEvent.cause === 'Rejected' && session.session.direction === 'outgoing') {
              showAlert(
                true,
                `This is an invalid or blacklisted destination. 
                  If you believe this is incorrect, please contact your administrator`,
                'warning'
              );
            }
            break;
          default:
            break;
        }
        removeRTCSession(sessionId);
      });

      newRTCSessionEvent.session.on('confirmed', (confirmedEvent) => {
        console.log('confirmed event', confirmedEvent);
        activeRTCSessions.current[sessionId].properties.remoteConnectionState = 'Connected';
        const session = activeRTCSessions.current[sessionId];

        let callType = session.properties.type;
        const transferCallExists = Object.values(activeRTCSessions.current).filter(val => val.properties.type === 'transfer').length > 0;
        const secondaryCallExists = Object.values(activeRTCSessions.current).filter(val => val.properties.type === 'secondary').length > 0;
        console.warn('accepted', callType, transferCallExists, secondaryCallExists);
        if (callType === 'primary') {
          setRemoteConnectionState('Connected');
        }

        if (callType === 'secondary') {
          setSecondCallState('Connected');
        }

        if (callType === 'transfer') {
          setConsultSessionState('Connected');
        }
      });

      newRTCSessionEvent.session.on('reinvite', (e) => {
        if (e.request.hasHeader("Remote-Party-Id")) {
          const remotePartyId = e.request.getHeader("Remote-Party-Id");
          const remotePartyIdArray = remotePartyId.split("<sip:");
          if (remotePartyIdArray.length >= 2) {
            let callerName = "";
            if (!remotePartyIdArray[0].includes("CID")) {
              callerName = remotePartyIdArray[0].replace(/['"]+/g, '').trim();
            }
            let callerNumberArray = [];
            if (remotePartyIdArray.length > 2) {
              callerNumberArray = remotePartyIdArray[2].split("@");
            } else {
              callerNumberArray = remotePartyIdArray[1].split("@");
            }
            if (callerNumberArray.length > 0) {
              const unparsedPhoneNum = callerNumberArray[0].replace(/\D/g, '');
              const remotePhoneNumber = parsePhoneNumberFromString(unparsedPhoneNum.startsWith('+') ? unparsedPhoneNum : `+1${unparsedPhoneNum}`).formatNational('National');

              if (callerName) {
                activeRTCSessions.current[session.id].properties.remoteCallerName = callerName;
                activeRTCSessions.current[session.id].properties.remoteCallerNumber = remotePhoneNumber;
              } else {
                activeRTCSessions.current[session.id].properties.remoteCallerName = remotePhoneNumber;
                activeRTCSessions.current[session.id].properties.remoteCallerNumber = '';
              }
            }
          }
        }
      })

      // ALL (most) DISCONNECT LOGIC LIVES HERE!
      newRTCSessionEvent.session.on('ended', (endedEvent) => {
        //console.log('rtcSessionEvent:ended', endedEvent);
        const session = activeRTCSessions.current[sessionId];
        if (!session) {
          console.log(`Session ${sessionId} is no longer in activeRTCSessionsList so ignoring event...`);
          return;
        }
        let callType = session.properties.type;
        //check if these are undefined to see if the other call types exist
        const primaryCall = Object.values(activeRTCSessions.current).filter((val) => val.properties.type === 'primary')[0];
        const transferCall = Object.values(activeRTCSessions.current).filter((val) => val.properties.type === 'transfer')[0];
        const transferredCall = Object.values(activeRTCSessions.current).filter((val) => val.properties.type === 'transferred')[0];
        const secondaryCall = Object.values(activeRTCSessions.current).filter((val) => val.properties.type === 'secondary')[0];

        if (endedEvent.originator === 'remote') {
          console.log('Remote Disconnected');
          const user = session.properties.remoteCallerName ?? session.properties.remoteCallerNumber;
          if(user) {
            showAlert(true, `${user} disconnected.`, 'danger');
          }
        }
        if (endedEvent.originator === 'local') {
          console.log('Local Disconnected');
        }

        let user = session.properties && session.properties.remoteCallerNumber ? session.properties.remoteCallerNumber : 'Other party';
        activeRTCSessions.current[sessionId].properties.remoteConnectionState = 'Disconnected';
        if (callType === 'primary') {
          setRemoteConnectionState('Disconnected');

          //primary call disconnected, but there was a secondary call so swap the two calls. 
          if (secondaryCall) {
            //TODO: handle situations where there's a secondary call, primary call hangs up and there's a consult leg
            if (transferCall) {
              try {
                transferCall.session.terminate();
              }
              catch (ex) {
                console.log('unable to terminate transfer leg ' + ex);
              }

            }
            handleSecondCallSwap(true);
            secondCallSession.current = null;
          } else if (!transferCall) {
            resetUser();
          }

          // No second call, and while the consult transfer was in progress
          // the primary call (the one that would be transferred) disconnected.
          // so this sets the consult leg to the new primary.
          if (transferCall) {
            rtcSession.current = consultSession.current;
            consultSession.current = null;
            activeRTCSessions.current[Object.values(activeRTCSessions.current).filter((val) => val.properties.type === 'transfer')[0].session.id].properties.type = 'primary';
            setRemoteConnectionState('Connected');
            setActiveHoldBtn(rtcSession.current.isOnHold().local);
            showAlert(true, `${user} Disconnected.`, 'danger');
          }
        }

        if (callType === 'secondary') {
          //second call just disconnects.
          secondCallSession.current = null;
          setSecondCallState('Disconnected');
        }

        if (callType === 'transfer') {
          //ended event for transfer, we have a secondary call AND a primary, which means we need to swap'em
          //since the current primary will be going away (transferred)
          if (secondaryCall && transferredCall) {
            console.log('ended event, transfer type and we have a secondary call')
            makeNewPrimary(secondaryCall.session.id);
            return;
          }

          if (!transferStarted.current) {
            //the consult leg disconnected but before the transfer was completed
            showAlert(true, `${user} disconnected`, 'danger') //remove?
            consultSession.current = null;
            handleConsultCancel();
          }

          //consult transfer completed
          setOpenTransferSettings(false);
          setConsultSessionState('');

          //Consult leg disconnected, successful transfer and there is NOT a second 
          //call waiting so go ahead and reset the phone
          if (!secondaryCall) {
            resetUser();
          }
        }

        removeRTCSession(sessionId);
      });

      //END RTCPeerConnection Events

      if (
        newRTCSessionEvent.session.direction === 'incoming' &&
        remoteConnectionState !== 'Ringing' &&
        secondCallState !== 'Ringing'
      ) {
        if (callType === 'primary') {
          setRemoteConnectionState('Ringing')
        }
        if (callType === 'secondary') {
          setSecondCallState('Ringing');
        }
        activeRTCSessions.current[sessionId].properties.remoteConnectionState = 'Ringing'
        if (!ringing) {
          setRinging(true);
          createNotification('Incoming Call', `${newRTCSessionEvent.session.remote_identity.display_name} ${remotePhoneNumber.formatNational('National')}`)
        }
      }
    });
  }, []);

  useEffect(() => {
    if (newRtcPeer === '') {
      return;
    }
    const session = Object.values(activeRTCSessions.current).filter((x) => x.properties.type === newRtcPeer)[0];
    session.session.connection.onaddstream = (e) => {
      setRemoteAudioRtcPeerConnection(e);
    };
    session.session.connection.onsignalingstatechange = (e) => {
      if (e.target.connectionState === 'new' && rtcSession.current.direction === 'outgoing') {
        if (newRtcPeer === 'primary') {
          setRemoteConnectionState('Dialing...');
        }
        if (newRtcPeer === 'secondary') {
          //future use if we decide to allow someone to place a second outbound call (not tranfer)
        }
        activeRTCSessions.current[session.session.id].properties.remoteConnectionState = 'Dialing...';
      }
    };
    setNewRtcPeer('');
  }, [newRtcPeer]);

  useEffect(() => {
    if (openTransferSettings) {
      setTransferFromNumber(activeRTCSessions.current[rtcSession.current.id].properties.remoteCallerNumber);
    }
  }, [openTransferSettings])

  function resetUser() {
    //console.log("No active RTC Sessions, resetting the user");
    setActiveMuteBtn(true);
    setActiveHoldBtn(false);
    setPhoneNumberToDial('');
    setDestinationIsValid(false);
    setRemoteAudioRtcPeerConnection(null);
    setRemoteConnectionState('Disconnected');
    showAlert(false);
    setRinging(false);
    setOpenTransferSettings(false);
    setOpenConsultTransferSettings(false);
    rtcSession.current = null;
    secondCallSession.current = null;
    consultSession.current = null;

  }

  function showAlert(show, message, type) {
    setAlertType(type);
    setAlertShow(show);
    setAlertMessage(message);
  }

  /* 
   * USER INPUT EVENTS
   */
  function handleDisconnectCall() {
    setPhoneNumberToDial('');
    if (rtcSession.current) {
      const disconnectingSessionId = rtcSession.current.id;
      if (rtcSession.current.direction === 'incoming' && ringing) {
        setRemoteConnectionState('Rejected');
        activeRTCSessions.current[rtcSession.current.id].properties.remoteConnectionState = 'Rejected';
        setRinging(false);
      }
      try {
        rtcSession.current.terminate();
      } catch (err) {
        console.log("Error terminating rtcSession: ", err)
        rtcSession.current = null;
      }
    }
  }

  function handleOnPress(val) {
    if (remoteConnectionState !== 'Disconnected' && rtcSession.current) {
      console.log(`DTMF: ${val}`);
      const response = rtcSession.current.sendDTMF(val);
      //userAgent.current.call('*3', mediaOptions.current);
    } else {
      handleNumberChange(phoneNumberToDial + val);
    }
  }

  function handleNumberChange(phone) {
    setDestinationIsValid(isDestValid(phone.replace(/[^0-9*#\+]/g, '')));
    setPhoneNumberToDial(phone.replace(/[^0-9*#\+]/g, ''));
  }

  function handlePlaceCall() {
    if (rtcSession.current && rtcSession.current.direction === 'incoming') {
      if (ringing) {
        setRinging(false);
      }
      rtcSession.current.answer(mediaOptions.current);
      setNewRtcPeer('primary');
      setRemoteConnectionState('Connected');
      activeRTCSessions.current[rtcSession.current.id].properties.remoteConnectionState = 'Connected';
      activeRTCSessions.current[rtcSession.current.id].properties.callStartTime = new Date();
      setPhoneNumberToDial(activeRTCSessions.current[rtcSession.current.id].properties.remoteCallerNumber);
    } else {
      userAgent.current.call(phoneNumberToDial, mediaOptions.current);
    }
  }

  function handleAcceptSecondCall() {
    if (secondCallSession.current && secondCallSession.current.direction === 'incoming') {
      if (ringing) {
        setRinging(false);
      }
      secondCallSession.current.answer(mediaOptions.current);
      setSecondCallState('Connected');

      handleSecondCallSwap();
      setNewRtcPeer('secondary');
    }
  }

  function handleRejectSecondCall() {
    const secondCallSessionId = Object.values(activeRTCSessions.current).filter(session => session.properties.type === 'secondary')[0].session.id;
    activeRTCSessions.current[secondCallSessionId].session.terminate();
    removeRTCSession(secondCallSessionId);
    if (ringing) {
      setRinging(false);
    }
    setSecondCallState('');
    secondCallSession.current = null;
  }

  function handleSecondCallSwap(putBothOnHold) {
    activeRTCSessions.current[rtcSession.current.id].properties.type = 'secondary';
    activeRTCSessions.current[secondCallSession.current.id].properties.type = 'primary';

    const newPrimary = activeRTCSessions.current[secondCallSession.current.id];
    const newSecondary = activeRTCSessions.current[rtcSession.current.id];

    rtcSession.current = newPrimary.session;
    secondCallSession.current = newSecondary.session;

    if (putBothOnHold === true) {
      rtcSession.current.hold();
      secondCallSession.current.hold();
      setActiveHoldBtn(true);
    } else {
      secondCallSession.current.hold();
      rtcSession.current.unhold();
    }

    setRemoteConnectionState(newPrimary.properties.remoteConnectionState);
    setSecondCallState(newSecondary.properties.remoteConnectionState);
  }

  function makeNewPrimary(sessionId) {
    console.log('Making ' + sessionId + ' new primary');
    activeRTCSessions.current[sessionId].properties.type = 'primary';
    setActiveHoldBtn(activeRTCSessions.current[sessionId].session.isOnHold().local);
    rtcSession.current = activeRTCSessions.current[sessionId].session;
    setRemoteConnectionState(activeRTCSessions.current[sessionId].properties.remoteConnectionState);
    for (const val of Object.values(activeRTCSessions.current)) {
      if (val.properties.type !== 'primary') {
        removeRTCSession(val.session.id);
      }
    }
    setSecondCallState('');
    secondCallSession.current = null;
    consultSession.current = null;
  }

  function handleMuteCall() {
    console.log("Muting")
    rtcSession.current.mute();
    setActiveMuteBtn(false);
  };

  function handleUnmuteCall() {
    console.log("Unmuting")
    rtcSession.current.unmute();
    setActiveMuteBtn(true);
  };

  function handleHoldCall() {
    rtcSession.current.hold();
    setActiveHoldBtn(true);
  };

  function handleUnholdCall() {
    rtcSession.current.unhold();
    setActiveHoldBtn(false);
  };

  function addRTCSession(newRTCSession, properties = {}) {
    console.log('Adding: ', newRTCSession);
    if (newRTCSession.id && activeRTCSessions[newRTCSession.id] === undefined) {
      properties.callStartTime = new Date();
      if (newRTCSession.direction === "incoming") {
        properties.remoteCallerNumber = newRTCSession.request_from_uri_user;
      } else if (newRTCSession.direction === "outgoing") {
        properties.remoteCallerNumber = newRTCSession.request_to_uri_user;
      }
      activeRTCSessions.current[newRTCSession.id] = { session: newRTCSession, properties };
      console.log(`Adding Active RTC Session (${newRTCSession.id}), new Active RTC Session List: `, activeRTCSessions.current);
      window.top.postMessage(
        `phone-in-use`,
        '*'
      )
    } else {
      console.log(`RTC Session ${newRTCSession} is already in Active RTC Session List. Ignoring...`)
    }
  }

  function removeRTCSession(sessionId) {
    console.log('Removing: ', activeRTCSessions.current[sessionId]);
    if (activeRTCSessions.current[sessionId]) {
      previousRTCSessions.current.push(activeRTCSessions.current[sessionId]);
      if (activeRTCSessions.current[sessionId].properties.type === 'secondary') {
        secondCallSession.current = null;
      }
      delete activeRTCSessions.current[sessionId];
      console.log(`Removed Active RTC Session (${sessionId}), new Active RTC Session List: `, activeRTCSessions.current);
      if (Object.keys(activeRTCSessions.current).length === 0) {
        resetUser();
      }
      if (previousRTCSessions.current.length === 10) {
        delete previousRTCSessions.current[9];
      }
      console.log(`Previous RTC Sessions: `, previousRTCSessions.current);
    }
    // If there are no sessions left send a message to the parent
    window.top.postMessage(
      `phone-idle`,
      '*'
    );
  }

  async function handleTransferCall(destination, type) {
    transferDestination.current = destination;
    try {
      transferDestination.current = parsePhoneNumberFromString(destination.startsWith('+') ? destination : `+1${destination}`).formatNational('National');
    }
    catch (ex) {
      transferDestination.current = destination;
    }
    const primarySession = Object.values(activeRTCSessions.current).filter(x => x.properties.type === 'primary')[0];
    const secondarySession = Object.values(activeRTCSessions.current).filter(x => x.properties.type === 'secondary')[0];
    if (type === "blind") {
      console.log(`Transferring ${primarySession.properties.remoteCallerNumber} to ${destination}`);
      let transferComplete = rtcSession.current.refer(destination);
      transferDestination.current = '';
      if (transferComplete.session) {
        if (transferComplete.session.localMediaStream && transferComplete.session.localMediaStream.active) {
          showAlert(true, `Invalid Destination`, 'warning');
        } else {
          setOpenTransferSettings(false);
          showAlert(false);
          if (secondarySession) {
            activeRTCSessions.current[primarySession.session.id].properties.type = 'transferred';
            makeNewPrimary(Object.values(activeRTCSessions.current).filter(x => x.properties.type === 'secondary')[0].session.id);
          }
        }
      } else {
        setOpenTransferSettings(false);
        showAlert(false);
        if (secondarySession) {
          activeRTCSessions.current[primarySession.session.id].properties.type = 'transferred';
          makeNewPrimary(Object.values(activeRTCSessions.current).filter(x => x.properties.type === 'secondary')[0].session.id);
        }
      }
    } else if (type === "consult") {
      setConsultSessionState('Dialing...');
      setShowCallManipulationBtns(false);
      primarySession.session.hold();
      console.log(`Calling ${destination} to prepare for consult transfer.`);
      consultSession.current = userAgent.current.call(destination, mediaOptions.current);
      addRTCSession(
        consultSession.current,
        {
          muted: !activeMuteBtn,
          type: 'transfer',
          remoteConnectionState: 'Dialing...'
        }
      );
      setNewRtcPeer('transfer');
      setActiveCaller(['to', consultSession.current.id]);
      setOpenTransferSettings(false);
      setOpenConsultTransferSettings(true);
    }
  }

  function handleSwapCallers() {
    const primarySession = Object.values(activeRTCSessions.current).filter(x => x.properties.type === 'primary')[0].session;
    if (activeCaller[0] === 'from') {
      primarySession.hold();
      consultSession.current.unhold();
      setActiveCaller(['to', primarySession.id]);
    } else {
      primarySession.unhold();
      consultSession.current.hold();
      setActiveCaller(['from', consultSession.current.id]);
    }
  }

  function handleConsultTransfer() {
    const primarySession = Object.values(activeRTCSessions.current).filter(x => x.properties.type === 'primary')[0];
    const consultTransferOptions = {
      replaces: consultSession.current
    };
    //console.log(`Transferring ${primarySession.properties.remoteCallerNumber} to ${transferDestination.current} to complete consult transfer`);
    activeRTCSessions.current[primarySession.session.id].properties.type = 'transferred';
    console.log('making primary transferred');
    primarySession.session.refer(transferDestination.current, consultTransferOptions);
    transferDestination.current = '';
    transferStarted.current = true;
    setOpenConsultTransferSettings(false);
    setShowCallManipulationBtns(true);
    setActiveCaller(['to', primarySession.session.id]);
    setRemoteConnectionState('');
  }

  function handleConsultCancel() {
    if (consultSession.current !== null) {
      try {
        consultSession.current.terminate();
      }
      catch (ex) {
        console.error('Error terminating consultSession: ' + ex);
      }
    }

    const session = Object.values(activeRTCSessions.current).filter(x => x.properties.type === 'primary')[0];
    rtcSession.current = session.session;
    setRemoteConnectionState(session.properties.remoteConnectionState);
    setOpenConsultTransferSettings(false);
    setShowCallManipulationBtns(true);
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter') {
      handlePlaceCall();
    }
  }

  function isDestValid(dest) {
    // this is the same regex from our number validator in the Kerauno platform
    const validDest = /^([1-9]\d{5,7}|[2-9]\d{2}[1-9]\d{6}|\+[1-9]\d{6,14})?-?([1-9]\d{5,7}|[2-9]\d{2}[1-9]\d{6}|\+[1-9]\d{6,14})[0-9,\*,\#]*?$/;
    const validFeatureCode = /\*[0-9]{1,4}/;
    let valid = dest.match(validDest) || dest.match(validFeatureCode);
    // check if it's an extension from 3-5 digits, the validDest regex will NOT allow for extensions
    if (!valid) {
      valid = dest.match(/^[0-9]{3,5}$/);
    }
    return valid;
  }

  function handleRefresh() {
    console.log("refreshing the main frame");
    window.top.postMessage(
      "refresh",
      "*"
    )
  }

  function handleMinifyBtn(minified) {
    if (minified) {
      window.top.postMessage(
        `change-softphone-width-${350}`,
        '*'
      );
    } else {
      window.top.postMessage(
        `change-softphone-width-${350}`,
        '*'
      );
    }
    setIsMinified(!isMinified);
  }

  function handleExternalBtn() {
    if (isExternal) {
      sendMessageToParentFrame('return-to-frame');
    }
    else {
      setFrameSize(50, null, containerRef.current);
      setIsLoading(true);
      userAgent.current.stop();
      setTimeout(() => { onPopOut(true) }, 500);
    }
  }


  /* 
   * END USER INPUT EVENTS
   */

  /*
 * Audio Controls
 */
  function handleAudioOutputChange(deviceId) {
    console.log("Audio Output changed", deviceId);
    setAudioOutputDeviceId(deviceId);
  }

  function handleAudioInputChange(deviceId) {
    console.log("Audio Input changed", deviceId);
    const newMediaOptions = {
      mediaConstraints: {
        audio: {
          deviceId: {
            exact: deviceId
          }
        },
        video: false,
      },
      pcConfig: {
        rtcpMuxPolicy: 'negotiate'
      }
    }
    mediaOptions.current = newMediaOptions;

    // TODO: Figure out how to change Audio input mid-call
    if (rtcSession.current) {
      rtcSession.current.renegotiate(newMediaOptions);
    }
  }

  function handleVolumeChange(volume) {
    console.log("Volume changed", volume);
    setAudioOutputVolume(volume);
  }

  /*
   * End Audio Controls
   */


  /* 
   * BUTTON VIEW LOGIC
   */
  useEffect(() => {
    setActiveCallBtn(
      ((registrationStatus === 'Registered'
        && (remoteConnectionState === '' || remoteConnectionState === 'Rejected' || remoteConnectionState === 'Disconnected'))
        || remoteConnectionState === 'Ringing'));
    setDisconnectBtnDisabled(!(
      registrationStatus === 'Registered'
      && (remoteConnectionState !== '' && remoteConnectionState !== 'Disconnected' && remoteConnectionState !== 'Rejected')));

    if (remoteConnectionState === 'Disconnected' || remoteConnectionState === 'Rejected') {
      setOpenConsultTransferSettings(false);
      setShowCallManipulationBtns(true);
    }

    if (secondCallState === 'Ringing' && isMinified === true) {
      setIsMinified(false);
    }

  },
    [registrationStatus, remoteConnectionState, secondCallState]);


  /* 
   * END BUTTON VIEW LOGIC
   */

  /*
   * BROWSER NOTIFICATION
   */
  useEffect(() => {
    if (Notification.permission !== 'granted' || 'Notification' in window) {
      Notification.requestPermission();
    }
  },
    []);

  /*
   * END BROWSER NOTIFICATION
   */

  function renderContainer() {
    return (
      <Container>
        <Row className='m-1'>
          <Col>
            <Button className='float-left' size="sm" variant="secondary" onClick={handleMinifyBtn}><FontAwesomeIcon icon={faWindowMinimize} /> </Button>
          </Col>
          <Col >
            <Row className='float-right'>
                <ButtonGroup>
                    <Button size="sm" variant="secondary" disabled={rtcSession.current !== null} onClick={handleExternalBtn}>
                    <FontAwesomeIcon rotation={isExternal ? 180 : null} icon={faExternalLinkAlt} />
                    </Button>
                    <SwitchFramePosition />
                </ButtonGroup>
            </Row>
          </Col>
        </Row>
        <Row className='m-1'>
          <Col>
            <Alert
              variant={alertType}
              show={alertShow}
              onClose={() => setAlertShow(false)}
              dismissible
            >
              {refreshAlert ? (
                <>
                  <span>
                    Softphone Disconnected.
                    Click <a className="cursor" onClick={handleRefresh}>here</a> to refresh your page and reregister.
                  </span>
                </>
              ) : (
                  <>
                    {alertMessage}
                  </>
                )
              }
            </Alert>
          </Col>
        </Row>
        { secondCallState && secondCallState !== 'Disconnected' ? (
          <div className='m-1 border border-warning rounded'>
            <Row className='m-1'>
              <Col>
                <span className='text-warning'>Call Waiting</span>
              </Col>
            </Row>
            <Row className='m-1'>
              <Col xs={6}>
                {secondCallState === 'Connected' ? (
                  < Button
                    variant='primary'
                    size='sm'
                    block
                    onClick={handleSecondCallSwap}
                    disabled={openTransferSettings || openConsultTransferSettings}
                  >
                    <FontAwesomeIcon icon={faRandom} /> Swap
                  </Button>
                ) : (
                    <CallOrAnswerBtn ringing={ringing} handleClick={handleAcceptSecondCall} />
                  )}
              </Col>
              <Col xs={6}>
                <EndOrRejectBtn handleClick={handleRejectSecondCall} />
              </Col>
            </Row>
            <Row className='m-1'>
              <Col xs={8}>
                {secondCallSession.current !== null ? activeRTCSessions.current[secondCallSession.current.id].properties.remoteCallerName ?? activeRTCSessions.current[secondCallSession.current.id].properties.remoteCallerNumber : ''}
              </Col>
              <Col xs={4}>
                <span className='text-muted'>
                  {
                    secondCallSession.current !== null && activeRTCSessions.current[secondCallSession.current.id] && !ringing
                      ?
                      getFormattedTimeSince(activeRTCSessions.current[secondCallSession.current.id].properties.callStartTime) :
                      ''
                  }
                </span>
              </Col>
            </Row>
          </div>
        ) : (
            <></>
          )
        }
        <div className={secondCallState && secondCallState !== 'Disconnected' ? 'm-1 border border-success rounded' : ''}>
          <Row className='m-1'>
            {activeCallBtn && alertMessage !== "" ? (
              <>
                <Col xs={6}>
                  <CallOrAnswerBtn ringing={ringing} handleClick={handlePlaceCall} disabled={!destinationIsValid} />
                </Col>
                <Col xs={6}>
                  <EndOrRejectBtn handleClick={handleDisconnectCall} disabled={disconnectBtnDisabled} />
                </Col>
              </>
            ) : (
                <>
                  <Col>
                    <MuteCallBtn handleMute={handleMuteCall} handleUnmute={handleUnmuteCall} active={activeMuteBtn} />
                  </Col>
                  {showCallManipulationBtns ? (
                    <>
                      <Col>
                        <HoldCallBtn handleHold={handleHoldCall} handleUnhold={handleUnholdCall} active={activeHoldBtn} />
                      </Col>
                      <Col>
                        <OpenTransferOptionsBtn
                          handleClick={() => setOpenTransferSettings(!openTransferSettings)}
                        />
                      </Col>
                      <Col>
                        <EndOrRejectBtn handleClick={handleDisconnectCall} disabled={disconnectBtnDisabled} />
                      </Col>
                    </>
                  ) : (
                      <>
                      </>
                    )
                  }
                </>
              )
            }
          </Row>

          <Row className="m-1">
            <Col>
              <Collapse in={openTransferSettings}>
                <div id="transfer-settings-collapse">
                  <TransferOptions openSettings={openTransferSettings} handleTransfer={handleTransferCall} isDestValid={isDestValid} />
                </div>
              </Collapse>
            </Col>
          </Row>


          <Row className="m-1">
            <Col>
              <Collapse in={openConsultTransferSettings}>
                <div id="consult-transfer-settings-collapse">
                  <TransferOptions
                    openSettings={openConsultTransferSettings}
                    consultLegState={consultSessionState}
                    handleTransfer={handleTransferCall}
                    isDestValid={isDestValid}
                    handleConsult={handleConsultTransfer}
                    handleConsultCancel={handleConsultCancel}
                    handleSwapCallers={handleSwapCallers}
                    consultTo={transferDestination.current}
                    consultFrom={transferFromNumber}
                    activeCaller={activeCaller[0]}
                  />
                </div>
              </Collapse>
            </Col>
          </Row>
          <Row className='m-1'>
            <Col xs={8}>
              {rtcSession.current && activeRTCSessions.current[rtcSession.current.id] && activeRTCSessions.current[rtcSession.current.id].properties.remoteCallerName ? activeRTCSessions.current[rtcSession.current.id].properties.remoteCallerName : ''}
              {rtcSession.current && activeRTCSessions.current[rtcSession.current.id] && activeRTCSessions.current[rtcSession.current.id].properties.remoteCallerNumber ? (<>{activeRTCSessions.current[rtcSession.current.id].properties.remoteCallerName ? <br /> : ''}{activeRTCSessions.current[rtcSession.current.id].properties.remoteCallerNumber}</>) : ''}
            </Col>
            <Col xs={4}>
              <span className="text-muted float-right">
                {remoteConnectionState}
                <br />
                {
                  rtcSession.current !== null && activeRTCSessions.current[rtcSession.current.id] && !ringing
                    ?
                    getFormattedTimeSince(activeRTCSessions.current[rtcSession.current.id].properties.callStartTime) :
                    ''
                }
              </span>
            </Col>
          </Row>

          {activeCallBtn ? (
            <Row className='m-1'>
              <Col>
                <InputGroup>
                  <InputGroup.Prepend>
                    <DialpadBtn
                      open={openDialpad}
                      handleClick={() => setOpenDialpad(!openDialpad)}
                      aria-controls="dialpad-collapse"
                      aria-expanded={openDialpad} />
                  </InputGroup.Prepend>
                  <FormControl
                    placeholder="8664595360"
                    aria-label="Phone Number"
                    onChange={(e) => handleNumberChange(e.target.value)}
                    onKeyPress={handleKeyPress}
                    value={phoneNumberToDial}
                    type="text"
                  />
                </InputGroup>
              </Col>
            </Row>
          ) : (
              <></>
            )}
          {rtcSession.current === null
            ?
            (<Row className='m-1'>
              <Col>
                <Collapse in={openDialpad}>
                  <div id="dialpad-collapse">
                    <Dialpad handleOnPress={handleOnPress} />
                  </div>
                </Collapse>
              </Col>
            </Row>
            ) : (
              <></>
            )}
          <Row className="m-1">
            {rtcSession.current !== null && activeRTCSessions.current[rtcSession.current.id] && !activeCallBtn
              ?
              (
                <Col xs={4}>
                  <DialpadBtn
                    open={openDialpad}
                    handleClick={() => setOpenDialpad(!openDialpad)}
                    aria-controls="dialpad-collapse-active-call"
                    aria-expanded={openDialpad} />
                </Col>
              ) : (
                <></>
              )}
            {rtcSession.current !== null && activeRTCSessions.current[rtcSession.current.id]
              ?
              (
                <Col xs={12}>
                  <Collapse in={openDialpad}>
                    <div id="dialpad-collapse-active-call">
                      <Dialpad handleOnPress={handleOnPress} />
                    </div>
                  </Collapse>
                </Col>
              ) : (
                <></>
              )}
          </Row>
        </div>
        <Row className="m-1">
          <Col xs={12}>
            <Button
              size="sm"
              variant="info"
              onClick={() => setOpenAudioSettings(!openAudioSettings)}
              aria-label="settings"
              aria-controls="settings-collapse"
              aria-expanded={openAudioSettings}
            >
              <FontAwesomeIcon icon={faCog} /> Audio Settings
            </Button>
            <p className="float-right text-secondary">{registrationStatus}</p>
          </Col>
        </Row>
        <Row className="m-1">
          <Col>
            <Collapse in={openAudioSettings}>
              <div id="settings-collapse">
                <VolumeSlider onVolumeChange={handleVolumeChange} />
                <AudioSelector onAudioOutputChange={handleAudioOutputChange} onAudioInputChange={handleAudioInputChange} inputDropdownActive={!rtcSession.current || remoteConnectionState === "Rejected"} />
              </div>
            </Collapse>
          </Col>
        </Row>
      </Container>
    );
  }

  function renderMinifiedContainer() {
    return (

      <Row className='m-1 align-items-center minified-row'>
        <Col xs={5}>
          <p className="text-center">
            <span className="text-muted">
              {
                rtcSession.current !== null && activeRTCSessions.current[rtcSession.current.id] && !ringing
                  ?
                  getFormattedTimeSince(activeRTCSessions.current[rtcSession.current.id].properties.callStartTime) :
                  '0:00'
              }
            </span>
            <br />
            {rtcSession.current !== null && activeRTCSessions.current[rtcSession.current.id] ? ( activeRTCSessions.current[rtcSession.current.id].properties.remoteCallerName && activeRTCSessions.current[rtcSession.current.id].properties.remoteCallerName !== '' ? activeRTCSessions.current[rtcSession.current.id].properties.remoteCallerName : activeRTCSessions.current[rtcSession.current.id].properties.remoteCallerNumber) : registrationStatus}
          </p>
        </Col>
        <Col xs={4}>
          <ButtonGroup>
            {activeCallBtn && alertMessage !== "" ? (
              <>
                <CallOrAnswerBtn ringing={ringing} handleClick={handlePlaceCall} disabled={!destinationIsValid} block={false} />
                <EndOrRejectBtn handleClick={handleDisconnectCall} disabled={disconnectBtnDisabled} block={false} />
              </>
            ) : (
                <>
                  <MuteCallBtn handleMute={handleMuteCall} handleUnmute={handleUnmuteCall} active={activeMuteBtn} block={false} />
                  <HoldCallBtn handleHold={handleHoldCall} handleUnhold={handleUnholdCall} active={activeHoldBtn} block={false} />
                  <EndOrRejectBtn handleClick={handleDisconnectCall} disabled={disconnectBtnDisabled} block={false} />
                </>
              )
            }
          </ButtonGroup>
        </Col>
        <Col className="float-right" xs={3}>
          <ButtonGroup>
            <Button size="sm" variant="secondary" onClick={handleMinifyBtn}><FontAwesomeIcon icon={faExpand} /></Button>
            <SwitchFramePosition />
          </ButtonGroup>
        </Col>
      </Row>

    );
  }

  return (
    <div>
      {
        !isLoading ?
          (
            <div>
              <PhoneAudio playRingtone={ringing} rtcPeerConnection={remoteAudioRtcPeerConnection} deviceId={audioOutputDeviceId} volume={audioOutputVolume} ringtone={ringtone} server={params.get('server')} />
              <div ref={containerRef}>
                {isMinified ? renderMinifiedContainer() : renderContainer()}
              </div>
            </ div>
          )
          :
          (
            <div className='text-center'>
              <Spinner size='lg' animation='border' variant='light' />
            </div>
          )
      }

    </div>
  );
}

export default Phone;