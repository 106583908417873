import React from 'react';
import { Row } from 'react-bootstrap';
import Digit from '../digit';

function Dialpad({handleOnPress, className, ...props}) {

  return (
    <>
      <Row className='dial-pad' noGutters="true">
        <Digit digit='1' onPressed={handleOnPress} />
        <Digit digit='2' onPressed={handleOnPress} />
        <Digit digit='3' onPressed={handleOnPress} />
      </Row>
      <Row className='dial-pad' noGutters="true">
        <Digit digit='4' onPressed={handleOnPress} />
        <Digit digit='5' onPressed={handleOnPress} />
        <Digit digit='6' onPressed={handleOnPress} />
      </Row>
      <Row className='dial-pad' noGutters="true">
        <Digit digit='7' onPressed={handleOnPress} />
        <Digit digit='8' onPressed={handleOnPress} />
        <Digit digit='9' onPressed={handleOnPress} />
      </Row>
      <Row className='dial-pad' noGutters="true">
        <Digit digit='*' onPressed={handleOnPress} />
        <Digit digit='0' onPressed={handleOnPress} />
        <Digit digit='#' onPressed={handleOnPress} />
      </Row>
    </>
  )
}

export default Dialpad;