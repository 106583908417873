import React from 'react';
import {
  Form
} from 'react-bootstrap';

function VolumeSliders(props) {

  function onVolumeSelected(e) {
    props.onVolumeChange(e.target.value);
  }

  return (
    <div>
      <Form>
        <Form.Group controlId="volumeSlider.selectVolume">
          <Form.Label> Volume:</Form.Label>
          <Form.Control
            type="range"
            onChange={onVolumeSelected}
          />
        </Form.Group>
      </Form>
    </div>
  )
}

export default VolumeSliders;