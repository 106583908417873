import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { TransferCallBtn, CancelBtn, SwapBtn, UnclickableBtn } from './callButtons';

function TransferOptions({
  handleTransfer,
  openSettings,
  isDestValid,
  handleConsult,
  handleConsultCancel,
  handleSwapCallers,
  consultTo,
  consultFrom,
  activeCaller,
  consultLegState,
  ...props
}) {
  const [transferDestination, setTransferDestination] = useState("");
  const [destinationValid, setDestinationValid] = useState(false);

  // clear the current transfer settings when settings get closed/opened reload
  useEffect(() => {
    if (openSettings) {
      setTransferDestination("");
      setDestinationValid(false);
    }
  }, [openSettings]);

  function updateDestination(destination) {
    setDestinationValid(isDestValid(destination));
    setTransferDestination(destination);
  }

  function buildTransferDestinationInput() {
    return (
      <Form onSubmit={e => {e.preventDefault();}}>
        <Form.Group controlId="transferOptions.transferDestination">
          <Form.Label>Transfer Destination:</Form.Label>
          <Form.Control
            as="input"
            type=""
            placeholder="Where would you like to transfer this call to?"
            value={transferDestination}
            onChange={(e) => updateDestination(e.target.value)}
          >
          </Form.Control>
        </Form.Group>
      </Form>
    )
  }

  function buildConsultForm() {
    return (
      <div>
          <hr/>
          <Row>
            <Col>
              <UnclickableBtn
                type={consultTo}
                icon={activeCaller === 'to' ? 'active-call' : false}
                active={activeCaller === 'to' ? true : false}
              />
            </Col>
            <Col>
              <TransferCallBtn disabled={consultLegState !== 'Connected'} handleClick={handleConsult} type="Transfer Now"  />
            </Col>
          </Row>
          <Row className="mt-1 mb-1">
            <Col>
              <Row className="justify-content-md-center">
                <Col xs={{ span:12, offset:6 }}>
                  <SwapBtn disabled={consultLegState !== 'Connected'} handleClick={() => handleSwapCallers()} />
                </Col>
              </Row>
            </Col>
            <Col>
            </Col>
          </Row>
          <Row>
            <Col>
              <UnclickableBtn
                type={consultFrom}
                icon={activeCaller === 'from' ? 'active-call' : false}
                active={activeCaller === 'from' ? true : false} />
            </Col>
            <Col>
              <CancelBtn handleClick={handleConsultCancel} type="Cancel Transfer"  />
            </Col>
          </Row>
          <hr/>
      </div>
    )
  }

  return (
    <div>
      {handleConsult ? (
        <>
          {buildConsultForm()}
        </>
        
      ) : (
        <>
          {buildTransferDestinationInput()}
            <Row className="m-1">
              <Col xs={6}>
                <TransferCallBtn handleClick={() => handleTransfer(transferDestination, "blind")} type="Transfer Now" disabled={!destinationValid} />
              </Col>
              <Col xs={6}>
                <TransferCallBtn handleClick={() => handleTransfer(transferDestination, "consult")} type="Consult Transfer" disabled={!destinationValid} />
              </Col>
            </Row>
            <hr />
        </>
        )
      }
      </div>
  );

}

export default TransferOptions;