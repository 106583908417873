import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsAlt } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { useLocation } from 'react-router-dom';


export const SwitchFramePosition = () => {
  const sendSwitchRequest = () => {
    window.top.postMessage(`switch-position`, "*");
  };

  const params = new URLSearchParams(useLocation().search);
  let version = 0
  if (params.has("version")) {
    version = parseInt(params.get("version"));
  }

  if (version >= 2021041) {
    return (
        <Button
          size="sm"
          variant="secondary"
          title={"Switch Position"}
          onClick={sendSwitchRequest}
        >
          <FontAwesomeIcon icon={faArrowsAlt} />
        </Button>
    );
  } else {
    return null;
  }
};
