import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPhone,
  faBell,
  faPhoneSlash,
  faMicrophoneSlash,
  faMicrophone,
  faPause,
  faExchangeAlt,
  faPhoneVolume,
  faTimes,
  faRandom,
  faTh,
  faChevronCircleUp,
  faChevronCircleDown
} from '@fortawesome/free-solid-svg-icons';

const icons = {
  "active-call": faPhoneVolume
}

function CallOrAnswerBtn({ block = true, ringing, handleClick, disabled, ...props }) {
  return (
    <Button
      variant='success'
      size='sm'
      block={block}
      onClick={handleClick}
      disabled={disabled && !ringing}>
      {ringing ? <FontAwesomeIcon icon={faBell} className='faa-shake animated' /> : <FontAwesomeIcon icon={faPhone} />}
    </Button>
  );
}

function MuteCallBtn({ block = true, active, handleMute, handleUnmute, ...props }) {
  if (active) {
    return (
      < Button
        variant='secondary'
        size='sm'
        block={block}
        onClick={handleMute}>
        <FontAwesomeIcon icon={faMicrophone} />
      </Button>
    )
  } else {
    return (
      < Button
        variant='secondary'
        size='sm'
        block={block}
        onClick={handleUnmute}>
        <FontAwesomeIcon icon={faMicrophoneSlash} color='#dd3545' />
      </Button>);
  }
}

function HoldCallBtn({ block = true, handleHold, handleUnhold, active, ...props }) {
  if (!active) {
    return (
      < Button
        variant='secondary'
        size='sm'
        block={block}
        onClick={handleHold}>
        <FontAwesomeIcon icon={faPause} />
      </Button>
    )
  } else {
    return (
      < Button
        variant='secondary'
        size='sm'
        block={block}
        onClick={handleUnhold}>
        <FontAwesomeIcon icon={faPause} color='#dd3545' />
      </Button>
    )
  }
}

function DialpadBtn({ handleClick, open, ...props }) {
  return (
    <Button
      variant='primary'
      size='sm'
      onClick={handleClick}>
      <FontAwesomeIcon icon={faTh} />&nbsp;<FontAwesomeIcon icon={open ? faChevronCircleUp : faChevronCircleDown} />
    </Button>
  );
}

function EndOrRejectBtn({ block = true, handleClick, disabled, ...props }) {
  return (
    <Button
      variant='danger'
      size='sm'
      block={block}
      disabled={disabled}
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faPhoneSlash} />
    </Button>
  )
}

function OpenTransferOptionsBtn({ handleClick, disabled, ...props }) {
  return (
      <Button
        variant='secondary'
        size='sm'
        block
        onClick={handleClick}
        disabled={disabled}
      >
        <FontAwesomeIcon icon={faExchangeAlt} />
      </Button>
  )
}

function TransferCallBtn({ disabled, handleClick, type, ...props }) {
  return (
      <Button
        variant='outline-success'
        size='sm'
        block
        disabled={disabled}
        onClick={handleClick}
      >
        <FontAwesomeIcon icon={type==="Transfer Now" ? faExchangeAlt : faPhoneVolume} />
        &nbsp;
        {type}
      </Button>
  )
}

function CancelBtn({ disabled, handleClick, type, ...props }) {
  return (
      <Button
        variant='outline-danger'
        size='sm'
        block
        disabled={disabled}
        onClick={handleClick}
      >
        <FontAwesomeIcon icon={faTimes} />
        &nbsp;
        {type}
      </Button>
  )
}

function SwapBtn({ handleClick, ...props }) {
  return (
    < Button
      variant='primary'
      size='sm'
      block
      onClick={handleClick}>
      <FontAwesomeIcon icon={faRandom} /> Swap
    </Button>
  )
}

function UnclickableBtn({ type, active, icon, ...props }) {
  return (
    <Button
      variant={active ? 'success' : 'dark'}
      size='sm'
      block
      disabled={true}
    >
      {
        icon ? 
        <>
          <FontAwesomeIcon icon={icons[icon]}/>
          &nbsp; 
        </>
        : 
        <></>
      }
      {type}
    </Button>
  )
}

export {
  CallOrAnswerBtn,
  MuteCallBtn,
  HoldCallBtn,
  DialpadBtn,
  EndOrRejectBtn,
  OpenTransferOptionsBtn,
  TransferCallBtn,
  CancelBtn,
  SwapBtn,
  UnclickableBtn
}

