import React, { useRef, useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { setFrameSize, setFramePositionFromBottom, getUuid } from './utils';

import './app.css';
import Phone from './phone/phone';

function App() {
    const externalWindow = useRef();
    const [isPrimary, setIsPrimary] = useState(false);
    const [isExternal, setIsExternal] = useState(false);
    const containerRef = useRef();

    useEffect(() => {
        let windowsArray = getWindowsArray() ?? [];

        if (window.name === '') {
            //let's give this window a unique name, a uuid
            window.name = getUuid();
        }
        if (window.name !== 'childUCaaSSoftphone') {
            if (windowsArray.filter((x) => x.isPrimary === true).length > 0) {
                //there's another value that is the primary already, so this one will take a backseat
                console.warn('This window is NOT the primary soft phone.');
                windowsArray.push({ uuid: window.name, isPrimary: false, dateInserted: new Date().getTime() });
                setIsPrimary(false);
                setFrameSize(35, 50, containerRef.current);
                setTimeout(() => {
                    setFrameSize(35, 50, containerRef.current); //should be able to remove this once most customers are >= 2020.10.1
                }, 1000);
                setFramePositionFromBottom('35px');
            } else {
                //no other windows claimed primary, so this will be
                console.warn('pushing... ', { uuid: window.name, isPrimary: true, dateInserted: new Date().getTime() });
                windowsArray.push({ uuid: window.name, isPrimary: true, dateInserted: new Date().getTime() });
                setIsPrimary(true);
            }
            saveWindowsArray(windowsArray);
        }

        if (window.name === 'childUCaaSSoftphone') {
            setIsPrimary(true);
        }

        //remove our uuid from local storage on unload
        window.addEventListener('beforeunload', (event) => {
            if (window.name === 'childUCaaSSoftphone') {
                return;
            }
            console.log('beforeUnload', window.name);
            const newWindowsArray = getWindowsArray().filter((x) => {
                const hoursDiff = (new Date().getTime() - x.dateInserted) / (1000 * 3600); //clean up any local storage that's old
                if (hoursDiff > 24) {
                    return false;
                }
                if (x.uuid !== window.name) {
                    return true;
                }
            });
            saveWindowsArray(newWindowsArray);
        });

        //this fires whenever local storage is modified, so we'll check to see if still primary.
        window.addEventListener('storage', (e) => {
            let newWindowsArray = JSON.parse(e.newValue);

            newWindowsArray.forEach(x => {
                if (x.uuid === window.name) {
                    if (externalWindow.current !== undefined) {
                        externalWindow.current.close();
                    }
                    setIsPrimary(x.isPrimary);
                }
            });
        });
    }, []);

    useEffect(() => {
        if (isPrimary) {
            setFrameSize(52, 350, containerRef.current);
            setTimeout(() => {
                setFrameSize(52, 350, containerRef.current); //should be able to remove this once most customers are >= 2020.10.1
            }, 1000);
            setFramePositionFromBottom('35px');
        } else {
            setFrameSize(35, 50, containerRef.current);
            setFramePositionFromBottom('35px');
        }
    }, [isPrimary])

    useEffect(() => {
        return (() => {
            if (externalWindow.current !== undefined) {
                externalWindow.current.removeEventListener('message', processMessage, false);
                window.close(externalWindow.current);
            }
        })
    },
        []);

    useEffect(() => {
        if (externalWindow.current !== undefined) {
            externalWindow.current.addEventListener('message', processMessage, false);
        }
    },
        [isExternal]);

    function processMessage(event) {
        if (event.origin === window.origin) {
            if (event.data === 'return-to-frame') {
                if (externalWindow.current !== undefined) {
                    externalWindow.current.close();
                    setIsExternal(false);
                    setFramePositionFromBottom('35px');
                }
            }
        }
    }

    function handlePopOut() {
        if (externalWindow.current === undefined || externalWindow.current.name === '') {
            setFrameSize(35, 35, containerRef.current);
            setFramePositionFromBottom('25%');
            let url = new URL(window.location.href);
            url.searchParams.delete('minify');
            url.searchParams.append('minify', 'false');
            url.searchParams.append('external', 'true');
            externalWindow.current = window.open(url, 'childUCaaSSoftphone', 'left=600,top=250,height=500,width=500');
            setIsExternal(true);
        }
    }

    function handleExternalBtn() {
        if (externalWindow.current !== undefined) {
            setFramePositionFromBottom('35px');
            externalWindow.current.close();
            setIsExternal(false);
        }
    }

    function handleMakePrimary() {
        let winArray = getWindowsArray();
        saveWindowsArray(winArray.map((x) => {
            if (x.isPrimary) {
                x.isPrimary = false;
            }
            if (x.uuid === window.name) {
                x.isPrimary = true;
                setIsPrimary(true);
            }
            return x;
        }));
    }

    function getWindowsArray() {
        return JSON.parse(localStorage.getItem('windowsArray'));
    }

    function saveWindowsArray(winArray) {
        localStorage.setItem('windowsArray', JSON.stringify(winArray));
    }

    return (
        <div ref={containerRef}>
            {isPrimary || isExternal ?
                (<div>
                    {isExternal ?
                        (<div className="restore-buttons">
                            <Button size="md" block variant="secondary" onClick={handleExternalBtn}><FontAwesomeIcon rotation={180} icon={faExternalLinkAlt} /></Button>
                        </div>)
                        :
                        (<Phone onPopOut={handlePopOut} />)}
                </div>
                )
                :
                (<div className="restore-buttons">
                    <Button size="md" block variant="secondary" onClick={handleMakePrimary}><FontAwesomeIcon icon={faPhoneAlt} /></Button>
                </div>)
            }
        </div>

    );
}

export default App;