import React, { useEffect, useRef } from 'react';

function PhoneAudio({playRingtone, rtcPeerConnection, deviceId, volume, ringtone, server}) {
    const audioRinging = useRef();
    const audioRemote = useRef();
    const volumeVal = volume / 100;

    const ringtoneSource = ringtone === "Generic" || server.includes('ihie') ? "/modern_tone.mp3" : "/lynk_ringtone.mp3";
    
    useEffect(() => {
        playRingtone ? audioRinging.current.play() : audioRinging.current.pause();
    }, 
    [playRingtone]);


    useEffect(() => {
        setRTCPeerConnection();
    },
    [rtcPeerConnection])

    useEffect(() => {
        async function changePhoneAudioDevice() {
            try {
                await setRTCPeerConnection();
                await audioRemote.current.setSinkId(deviceId);
                await audioRinging.current.setSinkId(deviceId);
            }
            catch (ex) {

            }
        }
        changePhoneAudioDevice();
    }, [deviceId]);

    useEffect(() => {
      async function changePhoneAudioVolume() {
          try {
            await setRTCPeerConnection();
            audioRemote.current.volume = volumeVal;
            audioRinging.current.volume = volumeVal;
          }
          catch(ex) {

          }
      }
      changePhoneAudioVolume();
  }, [volume]);

    async function setRTCPeerConnection() {
        if(rtcPeerConnection !== null && audioRemote.current.played) {
            audioRemote.current.srcObject = rtcPeerConnection.stream;
            audioRemote.current.play();
        }
    }


    return (
        <div>
            <audio id='audio-remote' ref={audioRemote} autoPlay></audio>
            <audio id='audio-ringing' src={ringtoneSource} ref={audioRinging} loop></audio>
        </div>
    );
}

export default PhoneAudio;
