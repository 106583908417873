import React, { useState, useEffect } from 'react';
import {
  Form
} from 'react-bootstrap';

function AudioSelector(props) {
  const [inputDevices, setInputDevices] = useState({});
  const [outputDevices, setOutputDevices] = useState({});
  const [totalAudioDevices, setTotalAudioDevices] = useState({});

  useEffect(() => {
    // Listen for changes to media devices and update the list accordingly
    navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);

    const fetchDevices = async () => {
      getConnectedDevices(['audioinput', 'audiooutput']);
    };
    fetchDevices();

    return (() => {
      navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange);
    });
  },
    []);

  async function handleDeviceChange(event) {
    await getConnectedDevices(['audioinput', 'audiooutput']);
  }

  useEffect(() => {
    const newAudioInputList = totalAudioDevices.audioinput;
    const newAudioOutputList = totalAudioDevices.audiooutput;
    setInputDevices(newAudioInputList);
    setOutputDevices(newAudioOutputList);
  },
    [totalAudioDevices]);

  function buildDeviceDropdown(type) {
    let devices = [];
    if (type === "input") {
      devices = inputDevices;
    } else {
      devices = outputDevices;
    }
    let devicesSelect = [];
    for (var i in devices) {
      const device = devices[i];
      const deviceId = device.deviceId;
      const label = device.label;
      devicesSelect.push(
        <option
          key={i}
          value={deviceId}
        >
          {label}
        </option>
      )
    }
    return devicesSelect;
  }

  function onAudioInputDeviceSelected(e) {
    props.onAudioInputChange(e.target.value);
  }

  async function onAudioOutputDeviceSelected(e) {
    props.onAudioOutputChange(e.target.value);
  }

  // Fetch an array of devices of a certain type
  async function getConnectedDevices(typesArray) {
    const devices = await navigator.mediaDevices.enumerateDevices();
    let rtnDevices = [];
    typesArray.forEach(type => {
      rtnDevices[type] = devices.filter(device => device.kind === type);
    }
    )
    setTotalAudioDevices(rtnDevices);
  }

  return (
    <div>
      <Form>
        <Form.Group controlId="audioSelector.selectAudioOutput">
          <Form.Label>Audio Output:</Form.Label>
          <Form.Control
            as="select"
            defaultValue="default"
            onChange={onAudioOutputDeviceSelected}
          >
            {buildDeviceDropdown('output')}
          </Form.Control>
        </Form.Group>
      </Form>
      <Form>
        <Form.Group controlId="audioSelector.selectAudioInput">
          <Form.Label>Audio Input:</Form.Label>
          <Form.Control
            as="select"
            defaultValue="default"
            onChange={onAudioInputDeviceSelected}
            disabled={!props.inputDropdownActive}
          >
            {buildDeviceDropdown('input')}
          </Form.Control>
          {!props.inputDropdownActive ? (
            <Form.Text muted >
              Audio Input cannot be altered while on an active call.
            </Form.Text>
          ) : (
              <></>
            )}
        </Form.Group>
      </Form>
    </div>
  )
}

export default AudioSelector;