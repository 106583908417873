import * as JsSIP from 'jssip';

export function createSipUaConfig(user, password, hostname) {
  if (!hostname || !user || !password) {
    throw new Error('Missing required parameter for SIP registration.');
  }

  const socket = new JsSIP.WebSocketInterface(
    `wss://${hostname}:8089/asterisk/ws`
  );
  socket.via_transport = 'ws';
  return {
    uri: `sip:${user}@${hostname}`, //new JsSIP.URI('sip', user, hostname),
    password: password,
    sockets: [socket],
    sipServer: hostname,
    session_timers: false,
    contact_uri: new JsSIP.URI(
      'sip',
      JsSIP.Utils.createRandomToken(8),
      hostname,
      null,
      { transport: 'wss' }
    ).toString(),
    //the contact uri is specified to hack around this: https://issues.asterisk.org/jira/browse/ASTERISK-24330
  };
}

export function createNotification(title, desc) {
  Notification.requestPermission();
  if (Notification.permission === 'granted') {
    var notification = new Notification(
      title,
      {
        body: desc,
        icon: 'kerauno-icon.png'
      },
    );
  };
}

export function getFormattedTimeSince(callStartTime) {
  const now = new Date();

  const secondsPassed = Math.floor((now.getTime() - callStartTime.getTime()) / 1000);
  const dummyDate = new Date(0);
  dummyDate.setSeconds(secondsPassed);

  if (secondsPassed < 599) {
    //m:ss
    return dummyDate.toISOString().substr(15, 4);
  }
  else if (secondsPassed < 3599) {
    //mm:ss
    return dummyDate.toISOString().substr(14, 5);
  }
  else if (secondsPassed < 35999) {
    //h:mm:ss
    return dummyDate.toISOString().substr(12, 7);
  }
  else {
    //hh:mm:ss
    return dummyDate.toISOString().substr(11, 8);
  }
}

function setFrameHeight(containerHeight) {
  if (containerHeight) {
    window.top.postMessage(
      `change-softphone-height-${containerHeight}`,
      '*'
    );
  }
}

function setFrameWidth(containerWidth) {
  if (containerWidth) {
    window.top.postMessage(
      `change-softphone-width-${containerWidth}`,
      '*'
    );
  }
}

export function setFramePositionFromBottom(val) {
  window.top.postMessage(
    `change-frame-position-from-bottom-${val}`,
    '*'
  );
}

export function sendMessageToParentFrame(message) {
  window.parent.postMessage(message, window.parent.origin);
}

export function getUuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function setFrameSize(height, width) {
  if(height) {
    setFrameHeight(height);
  } 
  if(width) {
    setFrameWidth(width);
  }
}